@font-face {
font-family: '__corsa_960529';
src: url(/_next/static/media/8d7f452979900d08-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 200;
font-style: normal;
}

@font-face {
font-family: '__corsa_960529';
src: url(/_next/static/media/2f490997e6db6971-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__corsa_960529';
src: url(/_next/static/media/f0c4dea9fc3eec39-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__corsa_960529';
src: url(/_next/static/media/a45f4039986bc021-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: '__corsa_960529';
src: url(/_next/static/media/f86df76be6c70aba-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 800;
font-style: normal;
}

@font-face {
font-family: '__corsa_960529';
src: url(/_next/static/media/830c1e031e9cc29b-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 900;
font-style: normal;
}@font-face {font-family: '__corsa_Fallback_960529';src: local("Arial");ascent-override: 101.67%;descent-override: 22.99%;line-gap-override: 0.00%;size-adjust: 113.11%
}.__className_960529 {font-family: '__corsa_960529', '__corsa_Fallback_960529'
}.__variable_960529 {--font-corsa: '__corsa_960529', '__corsa_Fallback_960529'
}

